<template>
  <div class="select-section">
    <b-button
      @click.stop="showSortByFilter"
      class="sort_btn float-right"
      :class="{ active: visibleSortBy }"
    >
      <span>{{ Object.keys(sortSelected).length > 0 ? sortSelected.text : defaultOptionText }}</span>
      <v-icon class="float-right" v-if="visibleSortBy">mdi-chevron-up</v-icon>
      <v-icon class="float-right" v-else>mdi-chevron-down</v-icon>
    </b-button>
    <div
      class="select-list"
      :class="{ open: visibleSortBy }"
      v-click-outside:showSortByFilter="false"
    >
      <template v-for="(item, i) in options">
        <div
          class="select-item"
          :key="i"
          @click="onClickSelectItem(item)"
          :class="{ active: sortSelected.text === item.text }"
        >
          {{ item.text }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultOptionText: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      visibleSortBy: false,
      sortSelected: {}
    };
  },
  methods: {
    showSortByFilter() {
      this.visibleSortBy = !this.visibleSortBy;
    },
    onClickSelectItem(item) {
      this.sortSelected = item;
      this.visibleSortBy = false;
      this.$emit('select', item);
    }
  }
};
</script>
